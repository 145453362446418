<template>
  <section>
    <div class="box" :class="isEditing ? 'box-warning' : 'box-primary'">
      <template v-if="mode == 'editor'">
        <div class="box-header">
          <span
            :class="isEditing ? 'clicable-title' : ''"
            @click.stop.prevent="$emit('panelProperties')"
          >
            <i class="fa fa-clock-o"></i>
            <span> {{ $t(title) }} </span>
          </span>
          <slot name="toolbar"></slot>
        </div>
      </template>
      <div class="box-header with-border" v-else>
        <span class="box-title">
          <i class="fa fa-clock-o"></i>
          <span> {{ $t(title) }} </span>
        </span>
        <slot name="toolbar"></slot>
      </div>
      <!-- /.box-header -->

      <div class="box-body">
        <div class="container-fluid">
          <div class="row" id="search-form">
            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12">
              <div class="form-group" style="position: relative">
                <label for="datetime-init">
                  {{ $tc("interval", 1) }}
                  <Tooltip
                    :title="
                      $t('hints.interval', { max: systemHistoryShortMaxDays })
                    "
                  />
                </label>
                <div>
                  <DatetimeRangePicker
                    v-if="contract && showDatetimePicker"
                    v-on:interval-changed="intervalChanged"
                    :startRef="dtini"
                    :min="maxInterval"
                  ></DatetimeRangePicker>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-5 col-sm-6 col-xs-12" v-if="!equipment">
              <div class="form-group">
                <label for="connector">
                  {{ $tc("connector") }}
                  <Tooltip :title="$t('hints.connector')" />
                </label>
                <v-select
                  class="connector-selector"
                  v-model="connector_id"
                  :options="connectorList || []"
                  :reduce="(option) => option.id"
                  multiple
                  label="name"
                />
              </div>
            </div>
            <div
              class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
              v-if="connector_id.length"
            >
              <div class="form-group">
                <label for="data">
                  {{ $tc("data") }}
                  <Tooltip :title="$t('hints.data')" />
                </label>
                <select
                  name="data"
                  id="data"
                  class="form-control"
                  v-model="data_id"
                  v-bind:disabled="busy"
                >
                  <option value>---</option>
                  <option
                    v-for="(data_item, ix) in data_list"
                    v-bind:key="ix"
                    v-bind:value="data_item.id"
                  >
                    {{ data_item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-8 col-sm-6">
              <div class="form-group">
                <label for="ev">
                  {{ $t("event") }}
                  <Tooltip :title="$t('hints.event')" />
                </label>
                <select
                  name="ev"
                  id="ev"
                  class="form-control"
                  v-model="system_event_id"
                  v-bind:disabled="busy"
                >
                  <option value>---</option>
                  <option
                    v-for="(ev, ix1) in event_list"
                    v-bind:key="ix1"
                    v-bind:value="ev.id"
                  >
                    {{ ev.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-1 col-md-2 col-xs-4 col-sm-6">
              <div class="btn-options no-wrap">
                <DownloadButton
                  :title="$t('hints.download')"
                  tableId="eventLogTable"
                  type="primary"
                  v-on:ready="downloading = true"
                  v-on:done="downloading = false"
                  v-bind:downloading="downloading"
                />
                <button
                  class="btn btn-default"
                  v-on:click.stop.prevent="resetFilters"
                  :title="$t('hints.reset_filters')"
                  style="margin-left: 2px"
                  v-if="
                    (connector_id.length && !equipment) ||
                    system_event_id ||
                    data_id
                  "
                >
                  <i class="fa fa-close"></i>
                </button>
                <span
                  v-if="$refs.connectionChart && $refs.connectionChart.dataset"
                  class="btn"
                  :class="connectionChart ? 'btn-primary' : 'btn-default'"
                  style="margin-left: 10px"
                  @click.stop.prevent="toggleChart"
                  :title="`${$t('chart')} - ${$t('connections')}`"
                >
                  <i class="fa fa-line-chart"></i>
                </span>
              </div>
            </div>
          </div>
          <EquipmentConnectionChart
            ref="connectionChart"
            v-show="connectionChart"
          />
          <SearchableTable
            v-if="show"
            class="searchable-table"
            tableId="eventLogTable"
            :items="parsedList"
            :commands="commands"
            :fields="fields"
            :searchEnabled="true"
            :multiColumnOrder="false"
            :clientSort="true"
            :pagination="pagination"
            :maxResult="maxResult"
            :pageJump="true"
            @pageChanged="onPageChanged($event)"
          >
          </SearchableTable>
        </div>
      </div>
      <Spin v-if="busy" />
    </div>
  </section>
</template>

<script>
import SearchableTable from "@/components/searchable-table.vue";
import DatetimeRangePicker from "@/components/datetime-range-picker.vue";
import DownloadButton from "@/components/download-button.vue";
import EventLogService from "@/services/event-log.js";
import DataService from "@/services/data.js";
import Spin from "@/components/spin.vue";
import Tooltip from "@/components/tooltip.vue";
import EquipmentConnectionChart from "@/components/equipment-connection-chart.vue";
// Localization
import messages from "@/i18n/events-history-panel";

function defaultData(self) {
  return {
    items: null,
    data_list: null,
    busy: false,
    dtini: null,
    dtend: null,
    connector_id: [],
    data_id: "",
    system_event_id: "",
    fields: [
      {
        name: "date_time_event",
        title: "datetime",
        parser: (item) => {
          return self.$dt.format(item.date_time_event);
        }
      },
      {
        name: "message",
        title: "message"
      },
      {
        name: "system_event",
        title: "event",
        parser: (item) => {
          // return item?.system_event?.name || "-";
          let name = item?.system_event?.name || "";
          if (!name && self.names) {
            let id = item?.system_event?.id || item?.system_event_id || "";
            name = id in self.names ? self.names[id].n : "";
          }
          return name || "-";
        }
      },
      {
        name: "system_sub_event",
        title: "system_sub_event",
        parser: (item) => {
          // return (item?.system_sub_event?.name ||"-");
          let name = item?.system_sub_event?.name || "";
          if (!name && self.names) {
            let id = item?.system_event?.id || item?.system_event_id || "";
            let e = id && id in self.names ? self.names[id] : null;
            if (e && e.se) {
              id =
                item?.system_sub_event?.id || item?.system_sub_event_id || "";
              name = id in e.se ? e.se[id].n : "";
            }
          }
          return name || "-";
        }
      },
      {
        name: "details",
        title: "details"
      },
      {
        name: "connector",
        title: "connector",
        parser: (item) => {
          return (item && item.connector && item.connector.name) || "-";
        }
      },
      {
        name: "data",
        title: "data",
        parser: (item) => {
          return (item && item.data && item.data.name) || "-";
        }
      },
      {
        name: "user",
        title: "user",
        parser: (item) => {
          return (item && item.user && item.user.email) || "-";
        }
      }
    ],
    commands: [],
    show: true,
    maxResult: 0,
    pageOffset: 1,
    sortOrder: null,
    downloading: false,
    showDatetimePicker: true,
    endsNow: false,
    connectionChart: false
  };
}
export default {
  name: "EventsHistoryPanel",
  i18n: { messages },
  components: {
    DatetimeRangePicker,
    DownloadButton,
    SearchableTable,
    Spin,
    Tooltip,
    EquipmentConnectionChart
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => null
    },
    connectorId: {
      type: Number,
      required: false,
      default: null
    },
    panelName: {
      type: String,
      default: "",
      required: false
    },
    screenId: {
      type: [String, Number],
      required: false,
      default: () => 0
    },
    title: {
      type: String,
      default: "",
      required: false
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return defaultData(this);
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    contract_id() {
      return this?.contract?.id || null;
    },
    systemHistoryShortMaxDays() {
      return (this?.contract?.system_history_short_max_days || 7);
    },
    maxInterval() {
      return this.systemHistoryShortMaxDays * 24;
    },
    parsedList() {
      return (this.items || []).map((i) => {
        if (i?.connector?.id && i?.connector?.id in this.taggedConnectors) {
          i.portal_data = this.taggedConnectors[i.connector.id] || null;
        }
        return i;
      });
    },
    taggedConnectors() {
      let entry = {};
      (this.connectorList || []).forEach((i) => {
        if (i?.portal_data?.tags?.length) {
          entry[i.id] = {
            tags: JSON.parse(
              JSON.stringify(i.portal_data.tags.map(({ text }) => ({ text })))
            )
          };
        }
      });
      return entry;
    },
    connectorList() {
      let lst = this.$store.getters["equipmentList"] || [];
      if (lst && lst.length) {
        return lst.sort(function(a, b) {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        });
      }
      return lst;
    },
    event_list() {
      return (
        (this.contract_id &&
          this?.$root?.config?.references?.system_event_list) ||
        []
      ).sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
    },
    isReady() {
      return true;
    },
    pagination() {
      return !this.downloading &&
        this.$root.config.equipment_selection &&
        "page_size" in this.$root.config.equipment_selection &&
        this.$root.config.equipment_selection.page_size
        ? true
        : false;
    },
    names() {
      let obj = null;
      (this?.$root?.config?.references?.system_event_list || []).forEach(
        (e) => {
          obj = obj || {};
          obj[e.id] = { n: e.name, se: {} };
          (e?.system_sub_event_list || []).forEach((s) => {
            obj[e.id].se[s.id] = { n: s.name };
          });
        }
      );
      return obj;
    },
    query() {
      if (!this?.contract) return null;
      let query = {
        contract_id: this.contract_id,
        start: moment(this.dtini)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(this.dtend)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss")
      };
      if (this.connector_id.length) {
        query.connector_ids = this.connector_id.join(',');
      }
      if (this.data_id) {
        query.data_id = this.data_id;
      }
      if (this.system_event_id) {
        query.system_event_id = this.system_event_id;
      }
      if (
        this.pagination &&
        "page_size" in this.$root.config.equipment_selection &&
        this.$root.config.equipment_selection.page_size
      ) {
        query.page_size = this.$root.config.equipment_selection.page_size;
      }
      return query;
    }
  },
  watch: {
    connector_id(n) {
      this.$emit("connectorIdChanged", n);
      if (n.length) {
        this.fetchData();
      } else {
        this.data_id = "";
      }
    },
    query(n) {
      if (n) {
        this.show = false;
        this.pageOffset = 1;
        this.fetchItems();
      }
    }
  },
  methods: {
    reset() {
      Object.assign(this.$data, defaultData());
    },
    resetFilters() {
      // it doesn't reset default connector if panel is being used in dashboard page
      // as there it cannot be changed
      this.connector_id = [];
      this.data_id = "";
      this.system_event_id = "";
    },
    intervalChanged(data) {
      this.dtini = data.startDate;
      this.dtend = data.endDate;
      this.updateEndsNow();
    },
    setup() {
      let tzos = new Date().getTimezoneOffset();
      let q;
      //========
      q = this.$utils.gup("start");
      this.dtini = (q && moment(`${q}.${tzos}Z`)) || moment().add(-24, "hours");
      q = this.$utils.gup("end");
      this.dtend = (q && moment(`${q}.${tzos}Z`)) || moment();
      //========
      q = this.$utils.gup("system_event_id");
      this.system_event_id = (q && parseInt(q)) || "";
      //========
      if (this.equipment && this.equipment.id) {
        this.connector_id = [this.equipment.id];
      } else if (this.connectorId != null) {
        this.connector_id = [this.connectorId];
      } else {
        q = this.$utils.gup("connector_id");
        this.connector_id = q !== '' ? [(q && parseInt(q)) || ""] : [];
      }
      this.updateEndsNow();
    },
    fetchItems() {
      let self = this;
      let query = JSON.parse(JSON.stringify(this.query));
      query.page = this.pageOffset;
      self.busy = true;
      self.service.fetch(query).then((response) => {
        this.$root.$emit("refreshDone");
        self.busy = false;
        let items = [];
        if (response) {
          if ("results" in response && response.results.length) {
            items = items = response.results;
            self.maxResult = response.count;
          } else if (response.length) {
            self.maxResult = response.length;
            items = response;
          }
        }
        self.$set(self, "items", items);
        this.show = true;
      });
      if (this.$refs.connectionChart) {
        this.$refs.connectionChart.fetch(this.dtini, this.dtend, this.connector_id);
      }
    },
    fetchData() {
      if (!this.connector_id.length) return;
      let query = {
        connector_id: (this.connector_id || []).join(',')
      };
      this.data_service.fetch(query).then((response) => {
        if (response && response.length) {
          this.$set(this, "data_list", JSON.parse(JSON.stringify(response)));
        } else {
          this.$set(this, "data_list", null);
        }
      });
    },
    onPageChanged(page) {
      this.pageOffset = page;
      this.fetchItems();
    },
    updateEndsNow() {
      // important: can not be dynamic/reactive
      this.endsNow =
        moment().format("YYMMDDHHmm") == this.dtend.format("YYMMDDHHmm");
    },
    onUserRefresh() {
      if (!this.endsNow) return;
      // required by the jquery calendar to get its visible interface updated
      this.showDatetimePicker = false;
      this.$nextTick(() => {
        this.showDatetimePicker = true;
      });
      this.fetchItems();
    },
    toggleChart() {
      this.connectionChart = !this.connectionChart;
      if (this.connectionChart && this.$refs.connectionChart) {
        this.$refs.connectionChart.refresh();
      }
    }
  },
  beforeCreate() {
    this.service = new EventLogService();
    this.data_service = new DataService();
  },
  created() {
    this.$root.$on("refreshPage", this.onUserRefresh);
    this.setup();
  },
  beforeDestroy() {
    this.$root.$off("refreshPage", this.onUserRefresh);
  }
};
</script>

<style scoped>
.box {
  margin: 0;
  padding: 0;
  box-shadow: none;
}
.box-body {
  padding-bottom: 50px;
  min-height: 40dvh;
}
.nav-tabs-custom {
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.btn-search {
  margin-right: 10px;
}
.label-search {
  color: transparent;
}
.daterange-container input {
  font-size: 85%;
}
.is-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .is-centered {
    padding-top: 0;
    padding-bottom: 1rem;
  }
  .btn-options {
    margin-top: 24px;
  }
}

@media (min-width: 769px) {
  #search-form {
    display: flex;
    align-items: center;
  }

  .is-centered {
    padding-top: 1rem;
  }

  .btn-options {
    margin-top: 10px;
  }
}

.no-wrap {
  white-space: nowrap;
}
.clicable-title:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #31708f;
}

.searchable-table {
  /* overflow-x: auto; */
}

.connector-selector::v-deep > div > div > .vs__search {
  min-height: 25px;
}
</style>
